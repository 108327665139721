.review-rule .modal-body .delete-confirm-footer button {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px
}

.review-rule #black-image-pagebar .spaui-pagebar .page-info .txt + .spaui-dropdown {
    display: inline-block;
}

.review-rule #empty-tip .repeater.empty, .review-rule #empty-tip .repeater:empty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 36px;
    color: rgba(0, 0, 0, .1);
    text-align: center;
    min-height: 380px
}