@charset "utf-8";

.dspnone {
  display: none;
}

.creative-review .green {
  color: green;
}

.creative-review .icon-visibility-visible {
  visibility: visible
}

.creative-review .icon-visibility-hidden {
  visibility: hidden
}

.creative-review .modal-reviewed .scroller {
  max-height: 640px
}

.creative-review .remark.remark-custom {
  position: absolute;
  font-size: 0px;
  background: #f7e81d;
  opacity: .4;
}

.creative-review .ads-item .media video {
  max-width: 100%;
  max-height: 360px
}

.creative-review .rule-elements .media video {
  object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%
}

.creative-review .break-all {
  word-break: break-all; /* 支持IE和chrome，FF不支持*/
  word-wrap: break-word; /* 以上三个浏览器均支持 */
}

.creative-review .div-img {
  max-width: 300px;
  max-height: 40px;
}

.creative-review .div-video {
  max-width: 220px;
  max-height: 40px;
}

.creative-review .complete-img {
  max-width: 100%;
  max-height: 100%;
}

.creative-review .text-placeholder {
  font-weight: 400;
  background: #fff;
  border-radius: 2px
}

.creative-review .color_black {
  color: black;
}

.creative-review a.color_black:hover,
a.color_black:visited,
a.color_black:link,
a.color_black:checked {
  color: black;
  border-color: none;
  text-decoration: none;
}

/*文本元素间距*/
.creative-review .ads-item .feed-basic-text + .feed-basic-text {
  margin-top: 10px
}

.creative-review .ads-item .feed-basic-text + .feed-params {
  margin-top: 10px
}

.creative-review .ads-item .feed-params + .feed-basic-text {
  margin-top: 10px
}

.creative-review .ads-item .feed-params + .feed-params {
  margin-top: 10px
}

.creative-review .feed .feed-inner:first-child {
  margin-bottom: 30px;
}

.creative-review .tips_card_header {
  padding: 5px;
  color: white;
}

.creative-review .icon-qq-bg {
  background: url(../images/icon-bqq.png) 50% 50% no-repeat;
}

.creative-review .media-card-img {
  width: 80px;
  height: 90px;
}

.creative-review .media-card-img img {
  width: 100%;
  height: 100%;
}


pre {
  white-space: pre-wrap; /* css3.0 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

pre {
  white-space: pre-wrap; /* css3.0 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.system_tool_em {
  font-size: 13px;
  color: grey;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.freeze-content {
  margin-top: 30px;
}

.freeze-delete-btn {
  margin: 30px 0 20px 0;
}

.freeze-info-item {
  margin: 10px 0;
}

.freeze-info-item span:nth-child(1) {
  display: inline-block;
  width: 74px;
  text-align: left;
}

.freeze-snap-info {
  margin-top: 20px;
}

.freeze-snap .cache-preview-item .empty {
  min-height: 380px;
  font-size: 36px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: rgba(0, 0, 0, .1);
}

.freeze-snap.review-cache {
  width: 1400px;
  margin: 50px auto 0 auto;
  padding-left: 0;
}

.freeze-snap .cache-preview {
  padding: 0 153px;
}

/*质检审核历史弹窗*/
.creative-review .table-reviewed .td-inner.wrap {
  white-space: normal;
}

.tipsarea .wd .channel-desc-item {
  margin-right: 30px;
}

.creativeReview .filter-result .result-item:first-of-type {
  margin-right: 25px;
}

.creativeReview .filter-result .result-item {
  display: inline-block;
  margin-right: 15px;
}


.nav-subspend-dowloadlog .nav-link {
  visibility: hidden;
}

.nav-subspend-dowloadlog:hover .nav-link {
  visibility: visible;
}

/*小黄条*/
.main .ad-audit-page-main {
  margin-top: 18px
}

#ad_search_batch_bar.with-tipsarea {
  top: 92px
}

.sec-header.sec_reverse {
  flex-direction: row-reverse;
}

.sec-header.sec_reverse .sec_container h3 {
  display: inline-block;
  line-height: 36px;
  vertical-align: 10px;
  margin-right: 10px;
  font-size: 20px;
}

.pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
}

.modal-vip-management-desc .vip-desc .define-li .desc .uids.uids-all .inner {
  overflow: scroll;
}

.creative-review .modal-normal .modal-body .table-wrapper.media-alert {
  width: 100%;
  overflow: auto;
}

.review-rule .spaui-table-thead-wraper {
  overflow: hidden;
}

.params-val {
  word-break: break-word;
}

.creative-history-dialog .spaui-tabs-head ul.spaui-tabs-nav {
  width: 1139px;
}

.rule-keywords-item.selected {
  background: #f6f8fd;
}

.table-reviewed .reason-item span.re {
  display: inline-block;
  font-size: 12px;
  margin-left: 15px;
  color: #f64b4b;
}

body::-webkit-scrollbar {
  height: 9px !important;
  width: 9px !important;
}

body::-webkit-scrollbar-thumb {
  border-style: solid;
  border-width: 1.8px;
  border-color: transparent;
  background-clip: padding-box;
  background-color: rgba(157, 165, 183, .4);
}

body::-webkit-scrollbar-thumb:hover {
  background: rgba(157, 165, 183, .7);
}

.modal-normal .modal-body .col-156 {
  width: 156px;
}

.certification_image_viewer_footer .viewer-action-group {
  background: rgba(0, 0, 0, .3);
}

.creative-review .main .account .account-inner .id-num .verify,
.creative-review .main .account .account-inner .id-num .entity-verify {
  top: 36px
}

.creative-review .ads-item .action-group span.info_item {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 10px;
}

.creative-review .feedbackbench .fdb-information .con .hd {
  flex-shrink: 0;
}

.creative-review .feedbackbench .fdb-information .fdb-action .buttons {
  width: 250px;
  flex-wrap: wrap;
}

.creative-review .feedbackbench .fdb-information .fdb-action .buttons button {
  margin-top: 15px;
  margin-right: 35px;
}

.creative-review .feedbackbench .fdb-information .fdb-action .buttons button:first-child {
  margin-right: 40px;
}

.spaui-table.nowrap .table > tbody > tr > td {
  white-space: nowrap;
}

.creative-review .aside-right-toggle {
  top: 118px;
}

.batch_checkbox {
  position: absolute;
  left: -26px;
  top: -2px;
}

.creative-review .ads-item .action-group p {
  margin-bottom: 10px;
}

/*limitedword*/
.modal-add-words .modal-body {
  padding: 0
}

.modal-add-words .modal-body .form-panel {
  height: 530px;
  padding: 0 80px
}

.modal-add-words .modal-body .form-panel textarea.spaui-input {
  height: 80px
}

.modal-add-words .modal-body .form-panel .form-group {
  width: 260px
}

.limited_word .table-panel .batch-bar {
  line-height: 70px;
  padding: 0 30px;
  margin-left: 30px;
  background-color: #2096f5;
  color: #fff;
  display: none;
  position: relative
}

.limited_word .table-panel .batch-bar .info-keyword {
  margin-right: 40px
}

.limited_word .table-panel .batch-bar .spaui-button {
  border-color: #fff
}

.limited_word .table-panel .batch-bar .close {
  width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  border-color: transparent;
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -12px
}

.limited_word .table-panel .batch-bar .icon-close {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden
}

.limited_word .table-panel .batch-bar .icon-close:after,
.limited_word .table-panel .batch-bar .icon-close:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  background-color: #fff
}

.limited_word .table-panel .batch-bar .icon-close:before {
  transform: rotate(45deg)
}

.limited_word .table-panel .batch-bar .icon-close:after {
  transform: rotate(-45deg)
}

.limited_word .table-panel .table-wraper .table > tbody > tr > td,
.table-panel .table-wraper .table > tbody > tr > th,
.table-panel .table-wraper .table > thead > tr > td,
.table-panel .table-wraper .table > thead > tr > th {
  border-color: #f2f2f2;
  height: 57px;
  vertical-align: middle
}

.limited_word .table-panel .table-wraper .table > tbody > tr > td .spaui-table-td-inner,
.table-panel .table-wraper .table > tbody > tr > th .spaui-table-td-inner,
.table-panel .table-wraper .table > thead > tr > td .spaui-table-td-inner,
.table-panel .table-wraper .table > thead > tr > th .spaui-table-td-inner {
  min-width: 50px;
  line-height: 22px
}

.limited_word .table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td {
  border-top: 0;
  padding: 10px 60px 30px 56px
}

.limited_word .table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner {
  padding: 30px;
  background-color: #f8f8f8
}

.limited_word .table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-title {
  padding-bottom: 20px;
  color: #000;
  font-weight: 700;
  display: flex
}

.limited_word .table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-title h3 {
  flex: 1
}

.limited_word .table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-title a {
  font-weight: 400;
  font-size: 12px
}

.limited_word .table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-wraper .table {
  color: #888;
  background-color: transparent
}

.limited_word .table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-wraper .table > tbody > tr > td,
.table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-wraper .table > tbody > tr > th,
.table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-wraper .table > thead > tr > td,
.table-panel .table-wraper .table > tbody > tr.spaui-table-tr-expand > td > .spaui-table-td-inner .table-wraper .table > thead > tr > th {
  height: 30px;
  font-weight: 400;
  border: 0
}

.limited_word .reg-step {
  width: 598px;
  height: 62px;
  line-height: 60px;
  border-bottom: 1px solid #dbdee4;
  overflow: hidden
}

.limited_word .reg-step ul {
  display: flex
}

.limited_word .reg-step ul li {
  background-color: #f5f6f8;
  text-align: center;
  color: #888;
  flex: 1;
  position: relative
}

.limited_word .reg-step ul li.active {
  background-color: #fff;
  z-index: 100;
  color: #333
}

.limited_word .reg-step ul li.active .icon-num {
  background-color: #5cb85c;
  color: #fff
}

.limited_word .reg-step ul li .graph-icon-del {
  display: none;
  width: 22px;
  height: 22px;
  line-height: 22px;
  margin-right: 3px
}

.limited_word .reg-step ul li .icon-num {
  width: 22px;
  height: 22px;
  line-height: 22px;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
  background-color: #d8d8d8;
  border-radius: 50%;
  vertical-align: middle;
  color: #888
}

.limited_word .form-panel {
  height: 530px;
  padding: 0 80px
}

.limited_word .form-panel .form-title {
  padding: 20px 0 10px 0;
  font-size: 16px;
  font-weight: 700;
}

.limited_word .form-panel .item-group {
  margin-bottom: 20px;
  display: flex
}

.limited_word .form-panel .item-group .label-name {
  display: inline-block;
  width: 85px;
  line-height: 36px;
  margin-bottom: 0
}

.limited_word .form-panel .item-group .label-name b {
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle
}

.limited_word .form-panel .item-group .form-group {
  width: 100%;
  margin-bottom: 0
}

.limited_word .form-panel .item-group .form-group .spa-ui-group {
  margin-top: 7px
}

.limited_word .form-panel .item-group .form-group .form-group-box {
  margin-top: 15px
}

.limited_word .has-error .help-block {
  margin: 0;
  position: absolute;
  bottom: -18px;
  left: 0
}

.limited_word_hiddenContent {
  display: none;
}

/*首屏loading动画*/
body {
  height: 100%;
}

.bk-loading,
.bk-loading-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: hsla(0, 0%, 100%, .9);
  z-index: 100;
}

.bk-loading .bk-loading-wrapper {
  text-align: center;
  line-height: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bk-loading .bk-loading1 {
  position: relative;
  width: 75px;
  height: 14px;
  margin: auto;
  display: inline-block;
}

.bk-loading .bk-loading1 .point {
  position: absolute;
  top: 0;
  width: 14px;
  height: 14px;
  animation-name: scale-animate;
  animation-name: scale-animate;
  animation-duration: .8s;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-direction: normal;
  transform: scale(.6);
  transform: scale(.6);
  border-radius: 19px;
}

.bk-loading .bk-loading1 .point1 {
  background-color: #fd6154;
  left: 0;
  animation-delay: .1s;
  animation-delay: .1s;
}

.bk-loading .bk-loading1 .point2 {
  background-color: #ffb726;
  left: 20px;
  animation-delay: .25s;
  animation-delay: .25s;
}

.bk-loading .bk-loading1 .point3 {
  background-color: #4cd084;
  left: 40px;
  animation-delay: .4s;
  animation-delay: .4s;
}

.bk-loading .bk-loading1 .point4 {
  background-color: #57a3f1;
  left: 60px;
  animation-delay: .55s;
  animation-delay: .55s;
}

.bk-loading-box-title,
.bk-loading-title {
  text-align: center;
  font-size: 14px;
  color: #63656e;
  line-height: normal;
  margin-top: 5px;
}


@keyframes scale-animate {
  0% {
    transform: scale(1);
    transform: scale(1)
  }

  to {
    transform: scale(.6);
    transform: scale(.6)
  }
}

@keyframes scale-animate {
  0% {
    transform: scale(1)
  }

  to {
    transform: scale(.6)
  }
}

.modal .rule-elements.wide {
  width: 100%;
}

.modal .rule-elements.no_background .spaui-checkbox:hover {
  background: none;
  border-bottom-color: rgba(0, 0, 0, .08)
}



/**/
.icon.hide-icon-default {
  display: none;
}

.piclist li.pic:hover .icon.hide-icon-default {
  display: inline-block;
}

/*审核历史二审流量包*/
.maxHeight550 {
  max-height: 550px;
  overflow: auto;
}

.creative-review .main .review-again button {
  width: 96px;
}

/* fix message 关闭按钮和信息主体处颜色不一致 */
.spaui-alert-error.error,
.spaui-alert-strongwarn {
  --spaui-alert-error-background: #fef7f7;
  --spaui-alert-color: #e63d2e;
}

.spaui-message-warning.spaui-message-light {
  --spaui-message-background: #fffae4;
  --spaui-message-border: 1px solid #ffedcc;
  --spaui-message-shadow: 0 4px 10px 0 rgba(102,73,20,.07),0 12px 24px 0 rgba(102,73,20,.08);
}

/* 外链标的物 图片viewer */
.external-link.viewer.spaui-component {
  z-index: 1200;
}

.external-link .viewer-overlay,
.external-link .viewer-inner,
.external-link .viewer-head,
.external-link .viewer-body,
.external-link .viewer-foot {
  z-index: 1200;
}

.inquire-con .help-block {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal .landpageid {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}

/*资质大像素图片处理*/

.qualifi-adm-show .viewer-pic img {
  max-width: 800px;
}

/* 修复pagebar 供xx页 比前面内容低 */
.main .spaui-pagebar .page-info {
  vertical-align: baseline;
}

.spaui-message-portal.custom-message-portal {
  z-index: 1050;
}

.spaui-dialog-portal .modal.show {
  z-index: 1000;
}

.spaui-drawer[tabindex] {
  z-index: 1000;
}
